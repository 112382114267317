:root {
  --pixel-size: 2px;
  --grid-cell: calc(var(--pixel-size) * 16);
  --bg: #9fa7e4;
}

@media (min-width: 700px) {
  :root {
    --pixel-size: 3px;
  }
}
@media (min-width: 1000px) {
  :root {
    --pixel-size: 4px;
  }
}
/* @media( min-width: 1000px ) {
    :root {
       --pixel-size: 5px;
    }
 } */

html,
body {
  height: 100%;
}

.page {
  background: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pixel-art {
  image-rendering: pixelated;
}

.frame {
  /* This is just for Pen decoration */
  width: calc(var(--pixel-size) * 160);
  height: calc(var(--pixel-size) * 144);
  outline: var(--pixel-size) solid #fff;
  z-index: 1;
  position: relative;
}

.camera {
  width: calc(var(--pixel-size) * 160);
  height: calc(var(--pixel-size) * 144);
  overflow: hidden;
  background: #61ddf7;
  position: relative;
}

.map {
  image-rendering: pixelated;
  background-image: url('Kitchen.png');
  background-size: 100%;
  width: calc(30 * var(--grid-cell));
  height: calc(40 * var(--grid-cell));
  position: relative;
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('Kitchen-b.png');
  background-size: 100%;
  z-index: 20; /* L'overlay sera au-dessus du personnage */
}

.character {
  width: calc(var(--pixel-size) * 30);
  height: calc(var(--pixel-size) * 30);
  position: absolute;
  overflow: hidden;
}

.shadow {
  width: calc(var(--grid-cell) * 2);
  height: calc(var(--grid-cell) * 2);
  position: absolute;
  left: 0;
  top: -7px;
  background: url('https://assets.codepen.io/21542/DemoRpgCharacterShadow.png')
    no-repeat no-repeat;
  background-size: 100%;
}

.character_spritesheet {
  /* position: absolute; */
  background: url('Cat.png') no-repeat no-repeat;
  background-size: 1200%;
  width: calc(var(--pixel-size) * 30);
  height: calc(var(--pixel-size) * 30); /* Hauteur d'une frame */
  background-position-x: calc(var(--pixel-size) * 0);
  background-position-y: calc(var(--pixel-size) * 0);
}
/* Direction bas */
/* .character[data-facing='down'] .character_spritesheet {
  background-position-x: calc(var(--grid-cell) * 0);
  background-position-y: calc(var(--grid-cell) * 0);
} */

/* Direction gauche */
/* L2 C1 */
.character[data-facing='left'] .character_spritesheet {
  background-position-x: calc(var(--pixel-size) * 0);
  background-position-y: calc(var(--pixel-size) * -32);
}

/* Direction droite */
/* L3 C1 */
.character[data-facing='right'] .character_spritesheet {
  background-position-x: calc(var(--pixel-size) * 0);
  background-position-y: calc(var(--pixel-size) * -60);
}

/* Direction haut */
/* L4 C1 */
.character[data-facing='up'] .character_spritesheet {
  background-position-x: calc(var(--pixel-size) * 0);
  background-position-y: calc(var(--pixel-size) * -90);
}

/* Positionnement pour les diagonales */

/* Diagonale bas-gauche */
/* L9 C1 */
.character[data-facing='down-left'] .character_spritesheet {
  background-position-x: calc(var(--pixel-size) * 0);
  background-position-y: calc(var(--pixel-size) * -240);
}

/* Diagonale bas-droite */
.character[data-facing='down-right'] .character_spritesheet {
  background-position-x: calc(var(--pixel-size) * 0);
  background-position-y: calc(var(--pixel-size) * -300);
}

/* Diagonale haut-gauche */
/* L10 C1 */
.character[data-facing='up-left'] .character_spritesheet {
  background-position-x: calc(var(--pixel-size) * 0);
  background-position-y: calc(var(--pixel-size) * -270);
}

/* Diagonale haut-droite */
.character[data-facing='up-right'] .character_spritesheet {
  background-position-x: calc(var(--pixel-size) * 0);
  background-position-y: calc(var(--pixel-size) * -330);
}

/* Animation de marche */
.character[data-walking='true'] .character_spritesheet {
  animation: walkAnimation 0.6s steps(3) infinite;
}

@keyframes walkAnimation {
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: calc(var(--pixel-size) * -90);
  }
}
.dpad {
  position: absolute;
  right: calc(var(--pixel-size) * 2);
  bottom: calc(var(--pixel-size) * 2);
  width: calc(var(--pixel-size) * 37);
  height: calc(var(--pixel-size) * 38);
}
.dpad-button {
  appearance: none;
  outline: 0;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
.dpad-button svg {
  display: block;
  height: calc(var(--pixel-size) * 13);
}

.dpad-button.pressed .Arrow_arrow-inset {
  stroke: #07c2cc;
}
.dpad-button.pressed .Arrow_arrow-body {
  stroke: #17dfea;
}

.dpad-up {
  position: absolute;
  left: calc(var(--pixel-size) * 12);
  top: 0;
}
.dpad-down {
  position: absolute;
  bottom: var(--pixel-size);
  left: calc(var(--pixel-size) * 12);
}
.dpad-left {
  position: absolute;
  top: calc(var(--pixel-size) * 12);
  left: 0;
}
.dpad-right {
  position: absolute;
  top: calc(var(--pixel-size) * 12);
  right: 0;
}

.dpad {
  user-select: none;
}

.corner_topleft,
.corner_topright,
.corner_bottomleft,
.corner_bottomright {
  position: absolute;
  width: var(--pixel-size);
  height: var(--pixel-size);
  background: var(--bg);
  z-index: 2;
}

.corner_topleft {
  top: calc(var(--pixel-size) * -1);
  left: calc(var(--pixel-size) * -1);
}
.corner_topright {
  top: calc(var(--pixel-size) * -1);
  right: calc(var(--pixel-size) * -1);
}
.corner_bottomleft {
  bottom: calc(var(--pixel-size) * -1);
  left: calc(var(--pixel-size) * -1);
}
.corner_bottomright {
  bottom: calc(var(--pixel-size) * -1);
  right: calc(var(--pixel-size) * -1);
}

.headline {
  position: absolute;
  top: calc(var(--pixel-size) * 2);
  right: calc(var(--pixel-size) * 2);
  width: calc(var(--pixel-size) * 75);
}
