.burger-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.burger-icon {
  cursor: pointer;
  color: white;
}

.menu-content {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #333;
  display: none;
  flex-direction: column;
  text-align: center;
}

.menu-content a {
  padding: 15px 0;
  color: white;
  text-decoration: none;
  font-size: 18px;
  border-bottom: 1px solid #444;
}

.menu-content a:hover {
  background-color: #444;
}

.menu-content.open {
  display: flex;
}

@media (min-width: 768px) {
  .burger-menu-container {
    display: none; /* Optionnel si vous voulez cacher le burger menu sur les grands écrans */
  }
}
