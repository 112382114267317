.transfer-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.wallets {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
}

.input-field {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 75%;
}

.input-max-container {
  display: flex;
  align-items: center;
}

.max-btn {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.max-btn:hover {
  background-color: #0056b3;
}

.swap-btn {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
  margin: 10px 0;
}

.swap-btn:hover {
  color: #007bff;
}

.arrow {
  font-size: 24px;
}

.transfer-info {
  text-align: center;
  font-size: 16px;
  color: #007bff;
  margin-bottom: 20px;
}

.transfer-btn-container {
  margin-top: 20px;
  text-align: center;
}

.transfer-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.transfer-btn:hover {
  background-color: #0056b3;
}

.input-with-gas-icon {
  background-image: url('img/gas_logo.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 25px 25px;
}
