.tap-game {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tap-image {
  margin: 3px;
  transition: transform 0.1s ease;
}

.tap-image img {
  width: 155px;
  height: 155px;
  user-select: none;
}

.tap-image:active {
  transform: scale(0.95);
}

.executing img {
  animation: vibrate 0.1s linear infinite;
}

@keyframes vibrate {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-2px, 2px);
  }
  50% {
    transform: translate(2px, -2px);
  }
  75% {
    transform: translate(-2px, -2px);
  }
  100% {
    transform: translate(2px, 2px);
  }
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 0px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #76c7c0;
  width: 0;
  transition: width 0.2s ease;
}

.info {
  margin-top: 10px;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 10;
}

.overlayLow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 85%;
  background: rgb(207, 203, 169);
  color: rgb(207, 203, 169);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  opacity: 80%;
  z-index: 10;
}
