.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
.item {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.item:nth-child(odd) {
  grid-column: 1;
}

.item:nth-child(even) {
  grid-column: 2;
}

/* Centrer l'élément s'il est le seul dans la grille */
.grid-container:has(.item:only-child) {
  grid-template-columns: 1fr;
  justify-self: center;
  margin: auto;
  width: 50%;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.square-button {
  width: 160px;
  height: 160px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}

.button-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Styles pour l'image et la superposition */
.image-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  transition: opacity 0.5s;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}
