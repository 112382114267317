.App {
  text-align: center;
}

.appBody {
  min-height: 55vh;
  background-color: rgb(242, 239, 222);
}
/* .App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* Pour les écrans de bureau */
/* @media (min-width: 1024px) {
  .app-container {
    width: 80%; 
    height: auto;
    margin: 0 auto;
    background-color: rgb(85, 255, 0);
  }
} */

/* Pour les tablettes */
/* @media (min-width: 768px) and (max-width: 1023px) {
  .app-container {
    width: 90%;
    height: auto;
    margin: 0 auto;
    background-color: rgb(0, 13, 255);
  }
} */

/* Pour les mobiles */
@media (max-width: 767px) {
  .app-container {
    /* width: 100%;
    height: auto; */
    margin: 5px;
    background-color: rgb(242, 239, 222);
    color: black;
  }
}

.homeHeader {
  background-color: paleturquoise;
  width: 100%;
  height: 200px;
}

.appTitle {
  padding: 10px;
  font-size: x-large;
}
.logoHeader {
  width: 40%;
  margin: auto;
}

.logoHeader img {
  width: 100px;
}

.text-with-icon {
  display: inline-flex;
  align-items: center;
}

.inline-incon {
  width: 25px;
  height: 25x;
  margin-left: 4px; /* Ajustez cet espace selon vos besoins */
  vertical-align: middle;
}
