.lobby-menu {
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #95435b;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Assurez-vous que le menu est au-dessus du contenu */
}
.lobby-menu a {
  color: white;
  text-decoration: none;
  font-weight: 900;
}

.lobby-button {
  position: relative;
  color: white;
  text-decoration: none;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lobby-button::before {
  content: '';
  position: absolute;
  top: 0px;
  width: 30px;
  height: 30px;
  background-color: #f0e7d5; /* Couleur blanc cassé */
  border-radius: 50%;
  box-shadow:
    inset -4px -4px 6px rgba(31, 28, 28, 0.7),
    /* Reflets */ inset 4px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: -1;
}

.lobby-button span {
  margin-top: 35px; /* Espace entre le texte et le cercle */
}

.lobby-button.disabled::before {
  pointer-events: none;
  background-color: #7a7a7a; /* Gris pour les boutons désactivés */
}

.lobby-button.red::before {
  background-color: #f12929; /* Gris pour les boutons désactivés */
}

.lobby-button.orange::before {
  background-color: #e97713; /* Gris pour les boutons désactivés */
}

.lobby-button.green::before {
  background-color: #28a206; /* Gris pour les boutons désactivés */
}
